import Head from 'next/head';
import Script from 'next/script';
import { LIBUM_IMAGE_URL } from 'shared/constants/globals';

type ContainerProps = {
  title: string;
  description: string;
  keywords: string;
  url: string;
  children: React.ReactNode;
};

export default function Container({ title, description, keywords, url, children }: ContainerProps) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.ico" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.ico" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        <meta name="description" content={description} />
        <meta name="og:type" content="website" />
        <meta name="og:url" content={url} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:site_name" content="Libum" />
        {keywords !== '' && <meta name="og:keywords" content={keywords} />}
        <meta name="og:image" content={LIBUM_IMAGE_URL} />
      </Head>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-KGRQWQK3SJ"></Script>
      <Script id="analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){ dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'G-KGRQWQK3SJ');
        `}
      </Script>
      {children}
    </>
  );
}
