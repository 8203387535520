import Link from 'next/link';
import MuxPlayer from '@mux/mux-player-react';

import Container from 'components/container';
import {
  FlexRow,
  GridContainer,
  GridRow,
  GridRowDescription,
  GridRowDescriptionLink,
  GridDownloadLink,
  GridRowImage,
  GridRowVideoContainer,
  GridRowVideoPlayer,
  GridRowHeroContainer,
  GridRowHeroImg,
} from 'shared/styles/components';
import { GLACI_VIDEO_ID, LIBUM_KEYWORDS, LIBUM_SCHEDULING_DEMO_DYLAN, LIBUM_URL } from 'shared/constants/globals';

import HOVER_ARROW from 'public/icons/hover_arrow.svg';
import CALENDAR_ICON from 'public/icons/eva/calendar.svg';
import GLACI_COLOR from 'public/logos/glaci_color.svg';
import { getGlaciFeature, GlaciFeature } from 'shared/data/glaci';

export default function Landing() {
  const title = 'Libum | Making Credit Union Software a Piece of Cake';
  const description =
    'Libum is a software company driving the modernization of credit unions. Service your members faster with Glaci or NexumAPI.';

  const CU_LAMBDA: GlaciFeature | undefined = getGlaciFeature('cu-lambda');
  const PASSWORD_MANAGER: GlaciFeature | undefined = getGlaciFeature('password-manager');

  return (
    <Container title={title} description={description} keywords={LIBUM_KEYWORDS} url={`${LIBUM_URL}/`}>
      <div style={{ width: '100%' }}>
        {/* Glaci video */}
        <GridContainer>
          <GridRow className="is-header is-visible">
            <GLACI_COLOR />
            <div className="subtitle">A native app for Symitar credit unions.</div>
            <GridRowVideoContainer>
              <GridRowVideoPlayer>
                <MuxPlayer playbackId={GLACI_VIDEO_ID} streamType="on-demand" thumbnailTime={1} autoPlay="muted" loop />
              </GridRowVideoPlayer>
            </GridRowVideoContainer>
          </GridRow>
          <GridRowHeroContainer>
            <GridRowHeroImg
              src="/images/b994cd88-f435-467c-ac0f-e242a9bf6e71.svg"
              alt="Stylized image of various snowflakes in a container."
              className="background"
            />
          </GridRowHeroContainer>
        </GridContainer>
        {/* Features */}
        <GridContainer>
          <GridRow className="is-container is-visible image-right">
            <GridRowDescription className="image-right">
              <h2>Creative&nbsp;features for both Symitar and your CU</h2>
              <div className="subtitle text-medium">
                Glaci apps &amp; extensions are designed to improve operational efficiencies, streamline workflows, and
                augment the employee user experience.
              </div>
              <Link href="/glaci#explore" passHref legacyBehavior>
                <GridRowDescriptionLink>
                  Explore features
                  <HOVER_ARROW />
                </GridRowDescriptionLink>
              </Link>
            </GridRowDescription>
            <GridRowImage
              className="image-right"
              alt="Stylized image showing Glaci Apps and Core Extensions."
              src="/images/08acbcf2-8c01-421f-a07f-a17330f7b3b8.svg"
            />
          </GridRow>
        </GridContainer>
        {/* Take control */}
        <GridContainer className="bg-gray">
          <GridRow className="is-container is-visible image-left">
            <GridRowImage
              className="image-left"
              alt="Stylized image showing a typical workflow user interface in Glaci."
              src="/images/643550fa-477f-4a40-b03f-4ca09d52e0db.svg"
            />
            <GridRowDescription className="image-left">
              <h2>Take control of the Symitar core</h2>
              <div className="subtitle text-medium">
                Sit back and relax while Glaci enforces compliance. Employees won&apos;t have to worry about missing a
                step with beautiful and intuitive workflows.
              </div>
            </GridRowDescription>
          </GridRow>
        </GridContainer>
        {/* Over the air updates */}
        <GridContainer>
          <GridRow className="is-container is-visible image-right">
            <GridRowDescription className="image-right">
              <h2>The latest and greatest</h2>
              <div className="subtitle text-medium">
                Keep your credit union modern with over the air updates. We&apos;ll keep pushing new features, when
                you&apos;re ready - just click to accept them.
              </div>
            </GridRowDescription>
            <GridRowImage
              className="image-right"
              alt="Stylized image showing Glaci with an available update."
              src="/images/cb9abeda-5b74-454c-b92e-d4473c2e8bb0.svg"
            />
          </GridRow>
        </GridContainer>
        {/* Tip of the iceberg */}
        <GridContainer className="bg-gray">
          <GridRow className="is-container is-visible image-bottom">
            <GridRowDescription className="image-bottom">
              <h1>Just the tip of the iceberg</h1>
              <div className="subtitle text-medium">
                Building a platform opens the door for additive development. Whether you&apos;re creating functions in{' '}
                <Link href={`/glaci/${CU_LAMBDA?.id}`} className="text">
                  {CU_LAMBDA?.name}
                </Link>{' '}
                to automate Symitar actions or storing secrets in{' '}
                <Link href={`/glaci/${PASSWORD_MANAGER?.id}`} className="text">
                  {PASSWORD_MANAGER?.name}
                </Link>
                , know that this is just the beginning.
              </div>
            </GridRowDescription>
            <GridRowImage
              className="image-bottom"
              alt="Stylized image showing the integration of Glaci with Symitar."
              src="/images/e7117e1d-b08a-4a1c-84a4-0efc82e902ad.svg"
            />
          </GridRow>
        </GridContainer>
        {/* Ready to get started */}
        <GridContainer className="bg-gray">
          <FlexRow className="is-container is-download is-visible">
            <div className="snowflakes-container">
              {/* eslint-disable-next-line */}
              <img src="/images/ed51c367-ecc9-4924-8cfd-bd77bada4ffb.svg" alt="Stylized image of multiple snowflakes falling around container." />
            </div>
            <h4 className="text-title">Ready to get started?</h4>
            <Link href={LIBUM_SCHEDULING_DEMO_DYLAN} passHref legacyBehavior>
              <GridDownloadLink target="_blank">
                <CALENDAR_ICON />
                Schedule Demo
              </GridDownloadLink>
            </Link>
          </FlexRow>
        </GridContainer>
      </div>
    </Container>
  );
}
